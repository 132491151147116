import {
  Box,
  MenuItem,
  OutlinedTextFieldProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import RequiredIndicator from "./RequiredIndicator";

const useStyles = makeStyles({
  container: {
    minWidth: 500,
    whiteSpace: "normal",
  },
  lineClamp: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  heightFix: {
    height: 56,
  },
});

interface SelectProps extends Omit<OutlinedTextFieldProps, "variant"> {
  name: string;
  value: string | number;
  options: {
    label?: string;
    element?: JSX.Element;
    value: string | number;
    isDisabled?: boolean;
    helperText?: string;
  }[];
  errorMessage?: string;
  useCustomLabel?: boolean;
  useHeightFix?: boolean;
  hideLabel?: boolean;
  showRequiredIndicator?: boolean;
}

export function Select({
  options,
  value,
  name,
  errorMessage,
  helperText,
  label,
  useCustomLabel = true,
  useHeightFix = false,
  hideLabel = false,
  showRequiredIndicator = false,
  ...restProps
}: SelectProps): JSX.Element {
  const classes = useStyles();

  return (
    <>
      {useCustomLabel && !hideLabel && (
        <Box fontWeight="fontWeightMedium" mb="0.35em" fontSize="1rem">
          <>
            <span>{label}</span>
            {showRequiredIndicator ? <RequiredIndicator /> : null}
          </>
        </Box>
      )}

      <TextField
        error={Boolean(errorMessage)}
        helperText={errorMessage ?? helperText}
        fullWidth
        id={name}
        InputLabelProps={{
          shrink: true,
        }}
        color="primary"
        value={value ?? ""}
        variant="outlined"
        select
        label={
          useCustomLabel || hideLabel ? undefined : (
            <>
              <span>{label}</span>
              {showRequiredIndicator ? <RequiredIndicator /> : null}
            </>
          )
        }
        name={name}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxHeight: 400,
                maxWidth: 300,
              },
            },
            className: useHeightFix ? classes.heightFix : null,
          },
        }}
        {...restProps}
      >
        {options.map((option) => (
          <MenuItem
            className={classes.container}
            key={option.value}
            value={option.value}
            disabled={option?.isDisabled ?? false}
          >
            <Stack>
              <Typography className={classes.lineClamp}>
                {option.label ?? option.element ?? option.value}
              </Typography>

              {option.helperText ? (
                <Typography variant="caption" color="text.secondary">
                  {option.helperText}
                </Typography>
              ) : null}
            </Stack>
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}
