import { useSession } from "@core/hooks/useSession";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import api from "../../api";
import getMemberQueryKeys from "../../members/query-keys";
import { transformAPIError } from "../../utils";
import getAppointmentQueryKeys from "../query-keys";
import { Appointment } from "../types";

interface RescheduleAppointmentPayload {
  id: number;
  user_id: number;
  appointment_length: number;
  appt_has_forms: boolean;
  appt_type: string;
  cancelled_waive_late_fee_reason_notes: string;
  cancelled_waive_late_fee_reason: string;
  cancelled_waive_late_fee: number;
  cancelled_within_24_hours: number;
  notify_via_email: number;
  reschedule_reason: string;
  reschedule_review_notes: string;
  start_date: string;
  allow_booking_over_max_das: boolean;
  not_compliant_reason: string | null;
  can_waive_late_fee: boolean;
  not_late_reason: string | null;
}

const rescheduleAppointment = (
  values: RescheduleAppointmentPayload,
  accessToken: string
) =>
  api
    .post<Appointment>(
      `/v2/clinician/member_appointments/${values.id}/reschedule`,
      {
        notify_via_email: values.notify_via_email,
        back_stop_time_in_mins: 0,
        appt_type: values.appt_type,
        appt_has_forms: +values.appt_has_forms,
        appointment_length: values.appointment_length,
        start_date: values.start_date,
        gcal: null,
        reschedule_reason: values.reschedule_reason,
        reschedule_review_notes: values.reschedule_review_notes,
        cancelled_within_24_hours: values.cancelled_within_24_hours,
        cancelled_waive_late_fee: values.cancelled_waive_late_fee,
        cancelled_waive_late_fee_reason: values.cancelled_waive_late_fee_reason,
        cancelled_waive_late_fee_reason_notes:
          values.cancelled_waive_late_fee_reason_notes,
        allow_booking_over_max_das: values.allow_booking_over_max_das,
        not_compliant_reason: values.not_compliant_reason,
        can_waive_late_fee: values.can_waive_late_fee,
        not_late_reason: values.not_late_reason,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      }
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useRescheduleAppointment = (): UseMutationResult<
  Appointment,
  Error,
  RescheduleAppointmentPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => rescheduleAppointment(payload, accessToken), {
    onSuccess: (_, payload) => {
      return Promise.all([
        queryClient.invalidateQueries(
          getAppointmentQueryKeys(accessToken).appointmentByMember(
            payload.user_id
          )
        ),
        queryClient.invalidateQueries(
          getAppointmentQueryKeys(accessToken).appointmentQuickView(payload.id)
        ),
        queryClient.invalidateQueries(["member-appointments"]),
        queryClient.invalidateQueries(
          getMemberQueryKeys(accessToken).memberSchedulingOverview(
            payload.user_id
          )
        ),
        queryClient.invalidateQueries(
          getAppointmentQueryKeys(accessToken).allAppointmentsV2()
        ),
        queryClient.invalidateQueries(["upcoming-first-session"]),
      ]);
    },
  });
};
