import { useSession } from "@core/hooks/useSession";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import api from "../../api";
import getMemberQueryKeys from "../../members/query-keys";
import { transformAPIError } from "../../utils";
import getAppointmentQueryKeys from "../query-keys";
import { Appointment } from "../types";

interface ChangeDurationPayload {
  appointmentId: number;
  newDurationInMins: number;
  notifyMember: boolean;
}

const changeDuration = (payload: ChangeDurationPayload, accessToken: string) =>
  api
    .post<Appointment>(
      `/v1/therapy/appointments/${encodeURIComponent(
        payload.appointmentId
      )}/change_duration`,
      {
        new_duration_in_mins: payload.newDurationInMins,
        notify_member: payload.notifyMember,
      },
      {
        headers: {
          Authorization: accessToken,
        },
      }
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useChangeDuration = (): UseMutationResult<
  Appointment,
  Error,
  ChangeDurationPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => changeDuration(payload, accessToken), {
    onSuccess: (response) =>
      Promise.all([
        queryClient.invalidateQueries(
          getAppointmentQueryKeys(accessToken).appointmentByMember(
            response.user_id
          )
        ),
        queryClient.invalidateQueries(
          getMemberQueryKeys(accessToken).memberSchedulingOverview(
            response.user_id
          )
        ),
        queryClient.invalidateQueries(
          getAppointmentQueryKeys(accessToken).allAppointmentsV2()
        ),
        queryClient.invalidateQueries(["member-appointments"]),
        queryClient.invalidateQueries(["upcoming-first-session"]),
      ]),
  });
};
